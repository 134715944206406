@import '../../styles/customMediaQueries.css';

.root {
}
.staticPageWrapper {
  width: 100%;
  margin: 24px auto;
  max-width: 1240px;
  padding: 0 40px;

  @media (min-width: 1400px) {
    max-width: 1300px;
    padding: 0;
  }
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    max-width: 1240px;
  }
  @media (min-width: 320px) and (max-width: 575.98px) {
    padding: 0 20px;
  }
}
/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 278px;
  /* height: 67.5vh;
  max-height: 600px; */
  padding: 0;
  margin-bottom: 30px;
  @media(max-width:767px){
    margin-bottom:0;
  }

  /* @media (--viewportMedium) {
    min-height: 500px;
    height: 70vh;
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 500px;
    height: calc(70vh - var(--topbarHeightDesktop));
  } */
  /* @media (min-width: 375px) and (max-width: 767.98px) {
    margin-bottom: 30px;
  } */
  /* @media (min-width: 320px) and (max-width: 374.98px) {
    margin-bottom: 70px;
  } */
}

.hero {
  flex-grow: 1;
  padding: 16px 0px;
  /* padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  } */
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  /* overflow: auto; */
  overflow: hidden;

  background: #fff;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: 0 24px 51px 24px;
  @media (min-width: 1024px) and (max-width: 1199.98px) {
    width: 95%;
  }

  @media (--viewportMedium) {
    max-width: 100%;
    margin: 0 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0;
    margin: 0 auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
  max-width: 2000px;
  margin: 0 auto 93px auto;
  width: 90vw;
  @media(max-width:767px){
    max-width:100%;
    width:100vw;
    padding:0 15px;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  /* margin-top: 3vh; */
  /* padding: 60px 0; */
  margin-bottom: 0;
  /* @media (min-width: 375px) and (max-width: 475.98px) {
    padding: 40px 0;
  }
  @media (min-width: 320px) and (max-width: 374.98px) {
    padding: 60px 0;
  } */
  /* @media(max-width:767px){
    margin-top: 40px;
  } */
  max-width: 2000px;
  margin: 0 auto 50px auto;
  width: 90vw;
  @media(max-width:767px){
    max-width:100%;
    width:100vw;
    padding:0 15px;
    margin:35px auto 40px;
  }
  @media(max-width:575px){
    margin:45px auto 60px;
  }
  @media(max-width:420px){
    margin: 70px auto 60px;
  }

  @media(max-width:350px){
    margin:110px auto 60px;
  }
}

/* A bar on top of light sections */
.section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));
  display: none;
  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}

.loaderContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 100%;
  }
}
